import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

const ContactPage = (props) => {
    const { blurb } = props.data.contentfulContactPage

    const Text = ({ children }) => <p>{children}</p>
    const richTextOptions = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [INLINES.HYPERLINK]: (node, children) => {
                const { uri } = node.data
                return (
                    <a href={uri} target="_blank" rel="noreferrer">{children}</a>
                )
            }
        },
        renderText: text => {
            return text.split('\n').reduce((children, textSegment, index) => {
              return [...children, index > 0 && <br key={index} />, textSegment];
            }, []);
          },
    }


    return (
        <Layout>
            <div className="contact-page">
                <div className="blurb">{renderRichText(blurb, richTextOptions)}</div>
            </div>
        </Layout>
    )
}

export default ContactPage

export const query = graphql`
    query ContactPage {
        contentfulContactPage {
        blurb {
            raw
        }
        }
    }  
`

export const Head = () => {
    return (
        <>
            <title>Contact - BO MIRHOSSENI</title>
        </>
    )
}